import React, { useImperativeHandle, useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import Workspace, { Header, Center, Searchbar } from 'renderer/components/templates/Workspace';

import useMousetrap from 'renderer/hooks/useMousetrap';

import Search from 'renderer/components/misc/Search';
import { RightSidebar } from 'renderer/components/templates/Hero';
import Row from 'renderer/system/misc/Row';
import Section from 'renderer/system/misc/Section';
import Body, { BodyMode } from 'renderer/system/typography/Body';
import { useGetPlaySessionQuery } from './queries';
import * as s from './styles';
import SessionCommentsManager from 'renderer/components/panels/SessionCommentsManager';

type PlaySessionManagerProps = {
  newWindow?: Window;
  id?: string;
};

type GameSession = {
  sessionId?: string;
};

const PlaySessionManager: React.FC<PlaySessionManagerProps> = ({ id }) => {
  const currentTime = useRef(0.0);
  const { sessionId } = useParams<GameSession>();
  const video = useRef<VideoControls>(null);

  const gameSessionId = sessionId ? sessionId : id ?? 'unknown';

  const playSessionQuery = useGetPlaySessionQuery({
    variables: { id: gameSessionId },
  });

  const session = playSessionQuery.data?.playSession;

  useMousetrap(['/', 'command+k', 'ctrl+k'], async (event) => {
    event.preventDefault();
  });

  if (playSessionQuery.loading) {
    return <div>Loading...</div>;
  }

  if (!session || !playSessionQuery) {
    return <div>Error...</div>;
  }

  const handleTimeUpdate = (time: number) => {
    currentTime.current = time;
  };
  //const handleScrub = (comment: ArrayElement<GetSessionCommentsSubscription['getSessionComments']>) => () => {
  const handleScrub = (info: any) => () => {
    if (!video) return;

    const infoCreated = new Date(info.createdAt);
    const sessionCreated = new Date(session.createdAt);
    const offset = infoCreated.getTime() - sessionCreated.getTime();

    video.current?.scrub(offset);
  };

  const name = session.user ? `${session.user.firstName} ${session.user.lastName}` : 'Unknown';

  return (
    <Workspace>
      <Header>
        <HeaderManager name={name} />
      </Header>
      <RightSidebar>
        <SessionCommentsManager
          id={gameSessionId}
          session={session}
          getCurrentTime={() => currentTime.current}
          onClickComment={handleScrub}
        />
      </RightSidebar>
      <Center>
        <Video ref={video} controls autoPlay src={session.videoUrl} onTimeUpdate={handleTimeUpdate} />
      </Center>
      <Searchbar>
        <Search />
      </Searchbar>
    </Workspace>
  );
};

type VideoProps = {
  controls?: boolean;
  autoPlay?: boolean;
  src: string;
  onTimeUpdate?: (time: number) => void;
};

type VideoControls = {
  scrub: (to: number) => void;
};

const Video = React.forwardRef<VideoControls, VideoProps>(
  ({ src, onTimeUpdate, controls = false, autoPlay = false }, ref) => {
    const video = useRef<HTMLVideoElement>(null);
    useLayoutEffect(() => {
      const updateTime = () => {
        if (!video.current) return;

        if (onTimeUpdate) {
          onTimeUpdate(video.current.currentTime);
        }
      };

      if (!video.current) return;

      video.current.addEventListener('timeupdate', updateTime);

      const curr = video.current;

      return () => {
        if (!curr) return;

        curr.removeEventListener('timeupdate', updateTime);
      };
    }, [src, onTimeUpdate]);

    useImperativeHandle(ref, () => {
      return {
        scrub: (to: number) => {
          if (!video.current) return;

          video.current.currentTime = to;
        },
      };
    });

    return (
      <s.Video id="video" ref={video} controls={controls} autoPlay={autoPlay} crossOrigin="anonymous">
        <source src={src} />
      </s.Video>
    );
  }
);

type HeaderProps = {
  name: string;
};

const HeaderManager: React.FC<HeaderProps> = ({ name }) => {
  return (
    <>
      <div />
      <Section horizontal={3} vertical={3}>
        <Row>
          <Body mode={BodyMode.SECONDARY}>
            <span>Play Session /</span>
          </Body>
          <Body>
            <span>{name}</span>
          </Body>
        </Row>
      </Section>
      <div />
    </>
  );
};

export default observer(PlaySessionManager);

import styled from '@emotion/styled';
import { padding } from 'polished';

export const Wrap = styled.div(() => ({
  width: '100%',
  display: 'flex',
}));

export const Search = styled.div(() => ({
  width: '100%',
}));

export const Line = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const LineSpaced = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  ...padding(0, 24, 0, 0),
}));

export const EngineStatus = styled.div(({ theme }) => ({
  width: 300,
  verticalAlign: 'bottom',
  fontSize: 11,
  color: theme.colors.gray5,
  fontFamily: theme.fonts.sans,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
}));

import { useRef } from 'react';

type Actions = {
  focus: () => void;
  blur: () => void;
};

export const useFocus = (): [any, Actions] => {
  const htmlElRef = useRef<HTMLDivElement>(null);

  const focus = () => {
    if (htmlElRef.current) {
      htmlElRef.current.focus();
    }
  };

  const blur = () => {
    if (htmlElRef.current) {
      htmlElRef.current.blur();
    }
  };

  return [
    htmlElRef,
    {
      focus,
      blur,
    },
  ];
};

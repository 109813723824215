import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import Section from 'renderer/components/experience/Section';

import Card, { CardModes, VisualMode } from 'renderer/system/molecules/Card';
import Row, { Alignment } from 'renderer/system/misc/Row';
import Eyebrow from 'renderer/system/typography/Eyebrow';
import { useLevelData } from 'renderer/data/level';
import { relative } from 'renderer/utils/datetime';

import Separator from 'renderer/system/atoms/Separator';
import { useGetPlaySessionsSubscription } from './queries';
import Button, { ButtonModes } from 'renderer/system/atoms/Button';
import { PlaySessionFilter } from 'types';

type PlaySessionsProps = {
  game?: string;
};

const PlaySessions: React.FC<PlaySessionsProps> = ({ game }) => {
  const level = useLevelData();
  const navigate = useNavigate();

  const [favoritesPageIndex, setFavoritesPageIndex] = useState(0);
  const favoritesPageSize = 5;

  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 15;

  const handleFavoriteNextPage = () => {
    setFavoritesPageIndex((prev) => prev + 1);
  };

  const handleFavoritesPreviousPage = () => {
    setFavoritesPageIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNextPage = () => {
    setPageIndex((prevPageIndex) => prevPageIndex + 1);
  };

  const handlePreviousPage = () => {
    setPageIndex((prevPageIndex) => Math.max(prevPageIndex - 1, 0));
  };

  const gameId = game ? game : level.project?.id;

  const favoritesPlaySessionsSubscription = useGetPlaySessionsSubscription({
    variables: {
      gameId,
      pageIndex: favoritesPageIndex,
      pageSize: favoritesPageSize,
      filter: PlaySessionFilter.Favorites,
    },
    skip: !gameId,
  });

  const playSessionsSubscription = useGetPlaySessionsSubscription({
    variables: {
      gameId,
      pageIndex,
      pageSize,
      filter: PlaySessionFilter.All,
    },
    skip: !gameId,
  });

  if (playSessionsSubscription.loading) {
    return <Eyebrow>Loading...</Eyebrow>;
  }

  const playSessions = playSessionsSubscription.data?.getPlaySessions ?? [];

  if (playSessions.length <= 0) {
    return <Eyebrow>No play sessions recorded yet</Eyebrow>;
  }

  const favoriteSessions = favoritesPlaySessionsSubscription.data?.getPlaySessions ?? [];

  return (
    <Section vertical={4} horizontal={0}>
      <div>
        <Row alignment={Alignment.CENTER}>
          <Eyebrow>Favorites</Eyebrow>
          <Button
            mode={ButtonModes.TERTIARY}
            onClick={handleFavoritesPreviousPage}
            disabled={favoritesPageIndex === 0}
          >
            Previous
          </Button>

          <Button
            mode={ButtonModes.TERTIARY}
            onClick={handleFavoriteNextPage}
            disabled={favoriteSessions.length < favoritesPageSize}
          >
            Next
          </Button>
        </Row>

        {favoriteSessions.map((session) => {
          const onClick = () => {
            if (game) {
              navigate(`/game/session/recording/${session.sessionId}`);
            } else {
              level.selectPlaySession(session.sessionId);
            }
          };

          return (
            <Card
              mode={CardModes.HORIZONTAL}
              visual={VisualMode.SECONDARY}
              key={session.sessionId}
              onClick={onClick}
              header={`${session.user?.firstName} ${session.user?.lastName}`}
              subheader={relative(new Date(session.createdAt))}
              title={new Date(session.createdAt).toLocaleString()}
              secondary={{
                label: 'View',
                action: onClick,
              }}
            ></Card>
          );
        })}
      </div>

      <Separator />

      <Row alignment={Alignment.CENTER}>
        <Eyebrow>Sessions</Eyebrow>
        <Button mode={ButtonModes.TERTIARY} onClick={handlePreviousPage} disabled={pageIndex === 0}>
          Previous
        </Button>

        <Button
          mode={ButtonModes.TERTIARY}
          onClick={handleNextPage}
          disabled={playSessions.length < pageSize}
        >
          Next
        </Button>
      </Row>

      {playSessions.map((session) => {
        const onClick = () => {
          if (game) {
            navigate(`/game/session/recording/${session.sessionId}`);
          } else {
            level.selectPlaySession(session.sessionId);
          }
        };
        return (
          <Card
            mode={CardModes.HORIZONTAL}
            visual={VisualMode.SECONDARY}
            key={session.sessionId}
            onClick={onClick}
            header={session.user ? `${session.user.firstName} ${session.user.lastName}` : 'Anonymous'}
            subheader={relative(new Date(session.createdAt))}
            title={new Date(session.createdAt).toLocaleString()}
            secondary={{
              label: 'View',
              action: onClick,
            }}
          ></Card>
        );
      })}
    </Section>
  );
};

export default observer(PlaySessions);

import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPlaySessionQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetPlaySessionQuery = { __typename?: 'QueryRoot', playSession?: { __typename?: 'PlaySession', id: string, sessionId: any, videoUrl: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, avatarUrl?: string | null } | null, favorite?: { __typename?: 'PlaySessionFavorite', id: string, favorite: boolean } | null } | null };

export type CreateSessionCommentMutationVariables = Types.Exact<{
  sessionId: Types.Scalars['UUID']['input'];
  offset: Types.Scalars['Float']['input'];
  content: Types.Scalars['String']['input'];
}>;


export type CreateSessionCommentMutation = { __typename?: 'MutationRoot', createSessionComment: { __typename?: 'SessionComment', id: string, sessionId: any, content: string, offset: number, createdAt: any, updatedAt: any } };

export type SetFavoritePlaySessionMutationVariables = Types.Exact<{
  playSessionId: Types.Scalars['UUID']['input'];
  favorite: Types.Scalars['Boolean']['input'];
}>;


export type SetFavoritePlaySessionMutation = { __typename?: 'MutationRoot', setFavoritePlaySession: { __typename?: 'PlaySessionFavorite', id: string, favorite: boolean } };


export const GetPlaySessionDocument = gql`
    query GetPlaySession($id: String!) {
  playSession(id: $id) {
    id
    sessionId
    videoUrl
    createdAt
    updatedAt
    user {
      id
      firstName
      lastName
      avatarUrl
    }
    favorite {
      id
      favorite
    }
  }
}
    `;

/**
 * __useGetPlaySessionQuery__
 *
 * To run a query within a React component, call `useGetPlaySessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaySessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaySessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlaySessionQuery(baseOptions: Apollo.QueryHookOptions<GetPlaySessionQuery, GetPlaySessionQueryVariables> & ({ variables: GetPlaySessionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlaySessionQuery, GetPlaySessionQueryVariables>(GetPlaySessionDocument, options);
      }
export function useGetPlaySessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaySessionQuery, GetPlaySessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlaySessionQuery, GetPlaySessionQueryVariables>(GetPlaySessionDocument, options);
        }
export function useGetPlaySessionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPlaySessionQuery, GetPlaySessionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPlaySessionQuery, GetPlaySessionQueryVariables>(GetPlaySessionDocument, options);
        }
export type GetPlaySessionQueryHookResult = ReturnType<typeof useGetPlaySessionQuery>;
export type GetPlaySessionLazyQueryHookResult = ReturnType<typeof useGetPlaySessionLazyQuery>;
export type GetPlaySessionSuspenseQueryHookResult = ReturnType<typeof useGetPlaySessionSuspenseQuery>;
export type GetPlaySessionQueryResult = Apollo.QueryResult<GetPlaySessionQuery, GetPlaySessionQueryVariables>;
export const CreateSessionCommentDocument = gql`
    mutation CreateSessionComment($sessionId: UUID!, $offset: Float!, $content: String!) {
  createSessionComment(sessionId: $sessionId, offset: $offset, content: $content) {
    id
    sessionId
    content
    offset
    createdAt
    updatedAt
  }
}
    `;
export type CreateSessionCommentMutationFn = Apollo.MutationFunction<CreateSessionCommentMutation, CreateSessionCommentMutationVariables>;

/**
 * __useCreateSessionCommentMutation__
 *
 * To run a mutation, you first call `useCreateSessionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionCommentMutation, { data, loading, error }] = useCreateSessionCommentMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      offset: // value for 'offset'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateSessionCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionCommentMutation, CreateSessionCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionCommentMutation, CreateSessionCommentMutationVariables>(CreateSessionCommentDocument, options);
      }
export type CreateSessionCommentMutationHookResult = ReturnType<typeof useCreateSessionCommentMutation>;
export type CreateSessionCommentMutationResult = Apollo.MutationResult<CreateSessionCommentMutation>;
export type CreateSessionCommentMutationOptions = Apollo.BaseMutationOptions<CreateSessionCommentMutation, CreateSessionCommentMutationVariables>;
export const SetFavoritePlaySessionDocument = gql`
    mutation SetFavoritePlaySession($playSessionId: UUID!, $favorite: Boolean!) {
  setFavoritePlaySession(playSessionId: $playSessionId, favorite: $favorite) {
    id
    favorite
  }
}
    `;
export type SetFavoritePlaySessionMutationFn = Apollo.MutationFunction<SetFavoritePlaySessionMutation, SetFavoritePlaySessionMutationVariables>;

/**
 * __useSetFavoritePlaySessionMutation__
 *
 * To run a mutation, you first call `useSetFavoritePlaySessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFavoritePlaySessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFavoritePlaySessionMutation, { data, loading, error }] = useSetFavoritePlaySessionMutation({
 *   variables: {
 *      playSessionId: // value for 'playSessionId'
 *      favorite: // value for 'favorite'
 *   },
 * });
 */
export function useSetFavoritePlaySessionMutation(baseOptions?: Apollo.MutationHookOptions<SetFavoritePlaySessionMutation, SetFavoritePlaySessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFavoritePlaySessionMutation, SetFavoritePlaySessionMutationVariables>(SetFavoritePlaySessionDocument, options);
      }
export type SetFavoritePlaySessionMutationHookResult = ReturnType<typeof useSetFavoritePlaySessionMutation>;
export type SetFavoritePlaySessionMutationResult = Apollo.MutationResult<SetFavoritePlaySessionMutation>;
export type SetFavoritePlaySessionMutationOptions = Apollo.BaseMutationOptions<SetFavoritePlaySessionMutation, SetFavoritePlaySessionMutationVariables>;
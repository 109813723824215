import styled from '@emotion/styled';
import { padding } from 'polished';
import { Grid } from 'renderer/utils/styled';

type WorldProps = {
  left: boolean;
  right: boolean;
  footer: boolean;
  scoped: boolean;
};

export const World = styled.div<WorldProps>(({ scoped, left, right, footer }) => {
  const rightArea = right ? 'right-area' : '';
  const rightSize = right ? '305px' : '';
  const scopedArea = scoped ? 'scoped-actions' : '';
  const scopedSize = scoped ? '30px' : '';
  const leftArea = left ? 'left-area' : '';
  const leftSize = left ? '250px' : '';
  const footerSize = footer ? '250px' : '';

  let numColumns = 1;
  if (right) numColumns += 1;
  if (left) numColumns += 1;

  const gridTemplateColumns = `${leftSize} auto ${rightSize}`;
  const gridTemplateRows = `35px ${scopedSize} minmax(0, 1fr) ${footerSize} 50px`;
  const gridTemplateAreas = `
  "${'header '.repeat(numColumns)}"
  ${(right && scoped) || (left && scoped) ? `"${leftArea} ${scopedArea} ${rightArea}"` : ''}
  "${leftArea} center ${rightArea}"
  ${footer ? `"${'footer '.repeat(numColumns)}"` : ''}
  "${'searchbar '.repeat(numColumns)}"
  `;

  return [
    {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,

      display: 'grid',
      gridTemplateColumns,
      gridTemplateRows,
      gridTemplateAreas,
    },
  ];
});

export const Main = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.sans,
  color: theme.colors.white,

  display: 'grid',
  gridTemplateColumns: 'repeat(120, minmax(0, 1fr))',
  gridTemplateRows: 'repeat(120, minmax(0, 1fr))',
  gap: 1,
  background: 'transparent',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
}));

export const Header = styled.div(({ theme }) => [
  {
    gridArea: 'header',

    userSelect: 'none',
    appRegion: 'drag',

    fontFamily: theme.fonts.sans,
    color: theme.colors.white,
    background: theme.colors.gray0,
    fontSize: 10,
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    borderBottom: `1px solid ${theme.colors.black}`,

    WebkitUserSelect: 'none',
    WebkitAppRegion: 'drag',
  },
  process.env.IS_MAC && {
    ...padding(0, Grid.L, 0, 80),
  },
  !process.env.IS_MAC && {
    ...padding(0, Grid.L),
  },
]);

/*
export type PanelProps = {
  columns: number;
  rows: number;
  startColumn: number;
  startRow: number;
  highlight: boolean;
};
export const Panel = styled.div<PanelProps>(({ theme, highlight, columns, rows, startColumn, startRow }) => [
  {
    gridColumn: `${startColumn} / span ${columns}`,
    gridRow: `${startRow} / span ${rows}`,
    background: theme.colors.gray0,
    overflow: 'hidden',
    display: 'grid',
  },
  highlight && {
    ...border(1, 'solid', theme.colors.orange),
  },
]);
*/

export const Selector = styled.div(({ theme }) => ({
  gridArea: 'selector',

  fontFamily: theme.fonts.sans,
  color: theme.colors.white,
  background: theme.colors.orange,
  padding: '30px 0 0 0',

  WebkitUserSelect: 'none',
  //WebkitAppRegion: 'drag',
}));

export const Scroll = styled.div(() => ({
  height: '100%',
}));

// NOTE: Will eventually be deprecated in favor of just `Panel`
export const Center = styled.div(() => ({
  gridArea: 'center',
  height: '100%',
  width: '100%',
  background: '#000',
}));

export const Left = styled.div(({ theme }) => ({
  gridArea: 'left-area',
  height: '100%',

  display: 'grid',
  boxSizing: 'border-box',
  gridAutoRows: 'minmax(0, 1fr) 40px',
  background: theme.colors.gray0,
  borderTop: `2px solid ${theme.colors.black}`,
  gridTemplateAreas: `
    "left-main"
    "left-actions"
  `,
  gap: 10,
}));

export const Scoped = styled.div(({ theme }) => ({
  gridArea: 'scoped-actions',
  background: theme.colors.gray0,
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: `2px solid ${theme.colors.black}`,
  borderRight: `3px solid ${theme.colors.black}`,
  borderLeft: `3px solid ${theme.colors.black}`,
  ...padding(Grid.M),
}));

export const LeftMain = styled.div(() => ({
  gridArea: 'left-main',
  overflowY: 'auto',
}));

export const LeftActions = styled.div(() => ({
  gridArea: 'left-actions',
}));

export const Right = styled.div(({ theme }) => ({
  gridArea: 'right-area',
  height: '100%',

  display: 'grid',
  gridAutoRows: 'minmax(0, 1fr)',
  borderTop: `2px solid ${theme.colors.black}`,
  gap: 10,
  width: '100%',
  boxSizing: 'border-box',
}));

export const Bottom = styled.div(({ theme }) => ({
  gridArea: 'footer',
  height: '100%',

  boxSizing: 'border-box',
  borderTop: `3px solid ${theme.colors.black}`,
}));

export const Search = styled.div(() => ({
  gridArea: 'searchbar',
  height: '100%',

  boxSizing: 'border-box',
  background: '#262525',
  display: 'flex',
  alignItems: 'center',
  ...padding(0, Grid.M),

  '& > *': {
    flex: '1 0 auto',
  },
}));

export const ThinPanel = styled.div(({ theme }) => ({
  background: theme.colors.gray0,
  pointerEvents: 'auto',
  boxSizing: 'border-box',
  height: '100%',
}));

export const Panel = styled.div(({ theme }) => ({
  background: theme.colors.gray0,
  pointerEvents: 'auto',
  overflowY: 'auto',

  boxSizing: 'border-box',

  ...padding(Grid.M),
}));

export const Dialogs = styled.div(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1000,
}));

export const Notifications = styled.div(() => ({
  position: 'fixed',
  top: 45,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1001,
}));

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';

import Workspace, {
  Header as WHeader,
  LeftSidebar,
  Center,
  Searchbar,
  RightSidebar,
} from 'renderer/components/templates/Workspace';
//import Hero, { Main, RightSidebar, Searchbar } from 'renderer/components/templates/Hero';
import Dialog, {
  Header as DialogHeader,
  Content as DialogContent,
} from 'renderer/components/templates/Dialog';

import InvitationManager from 'renderer/components/panels/InvitationManager';

import Card, { CardModes } from 'renderer/system/molecules/Card';
import Section, { SectionMode } from 'renderer/system/misc/Section';
import Profile, { ProfileSize } from 'renderer/system/molecules/Profile';
import Title2 from 'renderer/system/typography/Title2';
import Body from 'renderer/system/typography/Body';
import { useUi } from 'renderer/data/ui';
import UserIcon from 'renderer/icons/User';
import Button, { ButtonModes, ButtonSizes } from 'renderer/system/atoms/Button';
import Row, { Alignment, Spacing } from 'renderer/system/misc/Row';
import PlaySessionsList from 'renderer/components/panels/PlaySessionsList';
import { simpleElapsed } from 'renderer/utils/datetime';

import * as s from './styles';

import { Group } from '@visx/group';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { Grid } from '@visx/grid';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { BoxPlot } from '@visx/stats';
import { scaleBand, scaleLinear } from '@visx/scale';
import { max } from '@visx/vendor/d3-array';
import { timeFormat } from '@visx/vendor/d3-time-format';
import { localPoint } from '@visx/event';

//import generateDateValue, { DateValue } from '@visx/mock-data/lib/generators/genDateValue';
import { useGetUserQuery, useCreateSessionMutation } from './queries';
import Eyebrow from 'renderer/system/typography/Eyebrow';
import { Login, Logout } from 'renderer/components/misc/LoginButton';
import Label from 'renderer/components/typography/Label';
import Header from 'renderer/components/typography/Header';

type Branch = {
  shortname: string;
};

type DateValue = {
  item: number;
  bucket: Date;
};

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

let tooltipTimeout: number;
const formatDay = timeFormat('%b %d');
const format = timeFormat('%b %d %I:%M');
const formatDate = (date: Date) => formatDay(date);
const formatter = (date: DateValue) => format(date.bucket);
const getDate = (d: DateValue) => formatDate(d.bucket);
// const getX = (d: DateValue) => new Date(d.bucket);
const getY = (d: DateValue) => d.item;
export const purple3 = '#a44afe';

const BranchSelection: React.FC = () => {
  const [, setSearchParams] = useSearchParams();

  const { shortname } = useParams<Branch>();
  const ui = useUi();
  const navigate = useNavigate();
  const userQuery = useGetUserQuery({ variables: { shortname: shortname ?? '' } });
  const user = userQuery.data?.auth?.user ?? null;
  const game = userQuery.data?.gameByShortname ?? null;
  const branches = game?.branches ?? [];
  const [createSession, createStatus] = useCreateSessionMutation();

  const handleCreateSession = (branchId: string) => async () => {
    const session = await createSession({ variables: { branchId } });
    const id = session.data?.createSessionWithBranch.id ?? null;
    if (!id) {
      return;
    }

    navigate(`/game/session/${id}`);
  };

  const info = userQuery.data?.auth?.invitationInfoByShortname;

  useEffect(() => {
    if (!info) return;

    setSearchParams({ invite: info.invitationToken }, { replace: true });
  }, [info, setSearchParams]);

  if (userQuery.loading || (createStatus.loading && createStatus.called)) {
    return (
      <Workspace>
        <Header>
          <Body>Ultimate Engine</Body>
        </Header>
        <LeftSidebar>
          <Title2>Loading</Title2>
        </LeftSidebar>
      </Workspace>
    );
  }

  if (!game) {
    return (
      <Workspace>
        <Header>
          <Body>Ultimate Engine</Body>
        </Header>
        <LeftSidebar>
          <Title2>You don&apos;t have access to this game</Title2>
        </LeftSidebar>
      </Workspace>
    );
  }

  const series = game.stats.timeline.map((item) => ({
    item: item.uniquePings,
    bucket: new Date(item.bucket),
  }));

  const boxes = game.stats.events;
  // @ts-ignore
  const cohort = Object.entries(Object.groupBy(game.stats.cohort, (item) => item.cohortBucket));

  return (
    <Workspace>
      <WHeader>
        <Body>Ultimate Engine</Body>
        {user && <Profile firstName={user.firstName} lastName={user.lastName} size={ProfileSize.TINY} />}
      </WHeader>
      <LeftSidebar>
        {user ? (
          <Section mode={SectionMode.Focus}>
            <Title2>Welcome back, {user.firstName}</Title2>
          </Section>
        ) : (
          <Section mode={SectionMode.Focus}>
            <Title2>Welcome</Title2>
          </Section>
        )}
        <Section mode={SectionMode.Focus}>
          <Title2>{game.name}</Title2>
        </Section>
        <Section horizontal={2} vertical={2}>
          <PlaySessionsList game={game.id} />
        </Section>
      </LeftSidebar>
      <RightSidebar>
        <Section horizontal={2} vertical={2}>
          {branches.length <= 0 ? (
            <Eyebrow>No branches published yet</Eyebrow>
          ) : (
            <div>
              <Body>Select a branch to create a game from</Body>
              <s.Scroll>
                {branches.map((branch) => (
                  <Section key={branch.id} vertical={2} horizontal={0}>
                    <Card
                      header={branch.name}
                      mode={CardModes.HORIZONTAL}
                      secondary={{ label: 'New Game', action: handleCreateSession(branch.id) }}
                    ></Card>
                  </Section>
                ))}
              </s.Scroll>
            </div>
          )}
        </Section>
      </RightSidebar>
      <Center>
        <s.Scroll>
          <Section horizontal={2} vertical={2}>
            <Card mode={CardModes.HORIZONTAL}>
              <Row>
                <Card header="Total play time" mode={CardModes.HORIZONTAL}>
                  <s.Average>{game.stats.totalGameplayTime}</s.Average>
                </Card>
                <Card header="Average play time" mode={CardModes.HORIZONTAL}>
                  <s.Average>{game.stats.averageGameplayTime}</s.Average>
                </Card>
                <Card header="# of unique players" mode={CardModes.HORIZONTAL}>
                  <s.Average>{game.stats.numberOfPlayers}</s.Average>
                </Card>
              </Row>
            </Card>
            <Row>
              <Header>Total game sessions per day</Header>
            </Row>
            <BasicBarGraph
              margin={{ top: 10, right: 10, bottom: 30, left: 30 }}
              dimensions={{ width: 600, height: 175 }}
              series={series}
            />
          </Section>
          {cohort.length > 1 && (
            <Section vertical={2} horizontal={2}>
              <s.Table>
                <thead>
                  <s.TableRow>
                    <s.TableHeader>Cohort #</s.TableHeader>
                    {/* @ts-ignore */}
                    {cohort[0][1].map((week: any) => (
                      <s.TableHeader key={week.returnBucket}>
                        <Body>{formatDay(new Date(week.returnBucket))}</Body>
                      </s.TableHeader>
                    ))}
                  </s.TableRow>
                </thead>
                <tbody>
                  {cohort.map(([cohortName, weeks], i) => {
                    return (
                      <s.TableRow key={cohortName}>
                        <s.TableHeader>{i + 1}</s.TableHeader>
                        {/* @ts-ignore */}
                        {weeks.map((week: any) => {
                          const cohortBucket = new Date(week.cohortBucket).getTime();
                          const returnBucket = new Date(week.returnBucket).getTime();

                          if (cohortBucket > returnBucket) {
                            return <s.TableHeader key={week.returnBucket}></s.TableHeader>;
                          } else {
                            return (
                              <s.TableCell key={week.returnBucket}>
                                <Body>{week.retentionRate}%</Body>
                                <Body>{simpleElapsed(week.totalTimeSeconds)}</Body>
                              </s.TableCell>
                            );
                          }
                        })}
                      </s.TableRow>
                    );
                  })}
                </tbody>
              </s.Table>
            </Section>
          )}
          <Section vertical={2} horizontal={2}>
            <RiverGraph
              margin={{ top: 10, right: 10, bottom: 30, left: 50 }}
              dimensions={{ width: Math.max(500, boxes.length * 30), height: 175 }}
              series={boxes}
            />
          </Section>
        </s.Scroll>
      </Center>
      {user ? (
        <Searchbar>
          <Row spacing={Spacing.GAPS} alignment={Alignment.CENTER}>
            <div>
              <Logout />
            </div>
            <div>
              <Row alignment={Alignment.CENTER} onClick={() => ui.openDialog('invitation')}>
                <Eyebrow>Invite</Eyebrow>
                <Button mode={ButtonModes.QUATERNARY} size={ButtonSizes.SMALL}>
                  <UserIcon />
                </Button>
              </Row>
            </div>
          </Row>
        </Searchbar>
      ) : (
        <Searchbar>
          <Row spacing={Spacing.GAPS} alignment={Alignment.CENTER}>
            <div />
            <div>
              <Login />
            </div>
          </Row>
        </Searchbar>
      )}
      {ui.isDialogOpen('invitation') && (
        <Dialog onClose={() => ui.closeDialog('invitation')}>
          <DialogHeader>Invite New User</DialogHeader>
          <DialogContent>
            <InvitationManager gameId={game.id} />
          </DialogContent>
        </Dialog>
      )}
    </Workspace>
  );
};

type Stats = {
  message: string;
  min: number;
  max: number;
  median: number;
  firstQuartile: number;
  thirdQuartile: number;
};

const x = (d: Stats) => d.message;
const min = (d: Stats) => d.min;
const maxGetter = (d: Stats) => d.max;
const median = (d: Stats) => d.median;
const firstQuartile = (d: Stats) => d.firstQuartile;
const thirdQuartile = (d: Stats) => d.thirdQuartile;
const formatY = (d: number): string => simpleElapsed(d / 1000);

type RiverGraphProps = {
  margin: { top: number; right: number; bottom: number; left: number };
  dimensions: { width: number; height: number };
  series: Stats[];
};

const RiverGraph: React.FC<RiverGraphProps> = ({ series, margin, dimensions }) => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData } = useTooltip<DateValue>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  const xMax = dimensions.width - margin.left - margin.right;
  const yMax = dimensions.height - margin.top - margin.bottom;

  // scales
  const xScale = scaleBand<string>({
    domain: series.map(x),
    padding: 0.2,
  });
  const yScale = scaleLinear<number>({
    // @ts-ignore
    domain: [0, max(series, maxGetter) as number],
    round: true,
    clamp: true,
  });

  xScale.range([0, xMax]);
  yScale.range([yMax, 0]);
  const bandwidth = xScale.bandwidth();

  return (
    <svg
      ref={containerRef}
      width="100%"
      height="100%"
      viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
    >
      <Grid
        top={margin.top}
        left={margin.left}
        xScale={xScale}
        yScale={yScale}
        width={xMax}
        height={yMax}
        stroke="#fff"
        strokeOpacity={0.1}
        xOffset={bandwidth / 2}
      />
      <Group top={margin.top} left={margin.left}>
        {series.map((stat) => (
          <BoxPlot
            key={stat.message}
            min={min(stat)}
            max={maxGetter(stat)}
            left={xScale(x(stat)) ?? 0 + 0.3 * bandwidth}
            firstQuartile={firstQuartile(stat)}
            thirdQuartile={thirdQuartile(stat)}
            median={median(stat)}
            boxWidth={bandwidth}
            fill="#FFFFFF"
            stroke="#FFFFFF"
            strokeWidth={1}
            valueScale={yScale}
          />
        ))}
      </Group>
      <AxisLeft
        scale={yScale}
        left={margin.left}
        top={margin.top}
        // @ts-ignore
        tickFormat={formatY}
        stroke="#333"
        tickStroke="#333"
        tickLabelProps={{
          fill: '#fff',
          fontSize: 6,
          textAnchor: 'end',
        }}
      />
      <AxisBottom
        top={yMax + margin.top}
        left={margin.left}
        scale={xScale}
        //tickFormat={formatter}
        stroke="#333"
        tickStroke="#333"
        tickLabelProps={{
          fill: '#fff',
          fontSize: 7,
          textAnchor: 'middle',
        }}
      />
      {tooltipOpen && tooltipData && (
        <TooltipInPortal key={Math.random()} top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <Label>{formatter(tooltipData)}</Label>
          <Body>{tooltipData.item} concurrent sessions</Body>
        </TooltipInPortal>
      )}
    </svg>
  );
};

type BasicBarGraphProps = {
  margin: { top: number; right: number; bottom: number; left: number };
  dimensions: { width: number; height: number };
  series: { item: number; bucket: Date }[];
};

const BasicBarGraph: React.FC<BasicBarGraphProps> = ({ series, margin, dimensions }) => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip<DateValue>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  const xMax = dimensions.width - margin.left - margin.right;
  const yMax = dimensions.height - margin.top - margin.bottom;

  // scales
  const xScale = scaleBand<string>({
    domain: series.map(getDate),
    padding: 0.2,
  });
  const yScale = scaleLinear<number>({
    // @ts-ignore
    domain: [0, max(series, getY) as number],
    round: true,
  });

  xScale.range([0, xMax]);
  yScale.range([yMax, 0]);
  const bandwidth = xScale.bandwidth();

  return (
    <svg
      ref={containerRef}
      width="100%"
      height="100%"
      viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
    >
      <Grid
        top={margin.top}
        left={margin.left}
        xScale={xScale}
        yScale={yScale}
        width={xMax}
        height={yMax}
        stroke="#fff"
        strokeOpacity={0.1}
        xOffset={bandwidth / 2}
      />
      <Group top={margin.top} left={margin.left}>
        {series.map((bar, i) => (
          <rect
            key={`bar-stack-${i}`}
            // y={bar.y}
            height={yMax - yScale(bar.item)}
            x={xScale(getDate(bar))}
            y={yScale(bar.item)}
            width={bandwidth}
            fill="#00f0ff"
            onMouseLeave={() => {
              tooltipTimeout = window.setTimeout(() => {
                hideTooltip();
              }, 300);
            }}
            onMouseMove={(event) => {
              if (tooltipTimeout) clearTimeout(tooltipTimeout);
              // TooltipInPortal expects coordinates to be relative to containerRef
              // localPoint returns coordinates relative to the nearest SVG, which
              // is what containerRef is set to in this example.
              const eventSvgCoords = localPoint(event);
              const left = xScale(getDate(bar)) ?? 0 + bandwidth / 2;
              showTooltip({
                tooltipData: bar,
                tooltipTop: eventSvgCoords?.y,
                tooltipLeft: left,
              });
            }}
          />
        ))}
      </Group>
      <AxisLeft
        scale={yScale}
        left={margin.left}
        top={margin.top}
        //tickFormat={elapsed}
        stroke="#333"
        tickStroke="#333"
        tickLabelProps={{
          fill: '#fff',
          fontSize: 9,
          textAnchor: 'middle',
        }}
      />
      <AxisBottom
        top={yMax + margin.top}
        left={margin.left}
        scale={xScale}
        //tickFormat={formatter}
        stroke="#333"
        tickStroke="#333"
        tickLabelProps={{
          fill: '#fff',
          fontSize: 7,
          textAnchor: 'middle',
        }}
      />
      {tooltipOpen && tooltipData && (
        <TooltipInPortal key={Math.random()} top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <Label>{formatter(tooltipData)}</Label>
          <Body>{tooltipData.item} concurrent sessions</Body>
        </TooltipInPortal>
      )}
    </svg>
  );
};

export default observer(BranchSelection);

import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type Access = {
  __typename?: 'Access';
  accessToken: Scalars['UUID']['output'];
};

export type Allocation = {
  __typename?: 'Allocation';
  gameServerName: Scalars['String']['output'];
  tcp: Scalars['String']['output'];
  udp: Scalars['String']['output'];
};

export type Auth = {
  __typename?: 'Auth';
  email?: Maybe<Scalars['String']['output']>;
  games: Array<Game>;
  id: Scalars['ID']['output'];
  invitation?: Maybe<Invitation>;
  invitationInfo: InvitationInfo;
  invitationInfoBySessionId: InvitationInfo;
  invitationInfoByShortname: InvitationInfo;
  role?: Maybe<Role>;
  user: User;
  username?: Maybe<Scalars['String']['output']>;
};


export type AuthInvitationArgs = {
  gameId: Scalars['UUID']['input'];
};


export type AuthInvitationInfoArgs = {
  gameId: Scalars['UUID']['input'];
};


export type AuthInvitationInfoBySessionIdArgs = {
  sessionId: Scalars['UUID']['input'];
};


export type AuthInvitationInfoByShortnameArgs = {
  shortname: Scalars['String']['input'];
};


export type AuthRoleArgs = {
  gameId: Scalars['UUID']['input'];
};

export type Branch = {
  __typename?: 'Branch';
  createdAt: Scalars['DateTime']['output'];
  game: Game;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Cohort = {
  __typename?: 'Cohort';
  bucketNumber: Scalars['Int']['output'];
  cohortBucket: Scalars['DateTime']['output'];
  retentionRate: Scalars['Float']['output'];
  returnBucket: Scalars['DateTime']['output'];
  returningUsers: Scalars['Int']['output'];
  totalCohortUsers: Scalars['Int']['output'];
  totalTimeSeconds: Scalars['Float']['output'];
};

export type Comment = {
  __typename?: 'Comment';
  author: Auth;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  thread: Thread;
};

export type CommentMeta = {
  __typename?: 'CommentMeta';
  count: Scalars['Int']['output'];
  latest: Comment;
  profiles: Array<Auth>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CreateComment = {
  content: Scalars['String']['input'];
  threadId: Scalars['UUID']['input'];
};

export type CreateGame = {
  companyId: Scalars['String']['input'];
  gameName: Scalars['String']['input'];
  shortname: Scalars['String']['input'];
};

export type CreateThread = {
  content: Scalars['String']['input'];
  gameId: Scalars['UUID']['input'];
  levelId: Scalars['UUID']['input'];
  position: PositionInput;
};

export enum Environment {
  Local = 'LOCAL',
  Production = 'PRODUCTION',
  Staging = 'STAGING'
}

export type EventStats = {
  __typename?: 'EventStats';
  firstQuartile: Scalars['Float']['output'];
  max: Scalars['Float']['output'];
  median: Scalars['Float']['output'];
  message: Scalars['String']['output'];
  min: Scalars['Float']['output'];
  thirdQuartile: Scalars['Float']['output'];
};

export type Game = {
  __typename?: 'Game';
  branches: Array<Branch>;
  company: Company;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shortname: Scalars['String']['output'];
  stats: Stats;
  updatedAt: Scalars['DateTime']['output'];
};

export type Invitation = {
  __typename?: 'Invitation';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  game: Game;
  hasEmail: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invitationToken: Scalars['UUID']['output'];
  invitee: Auth;
  inviter: Auth;
  postInvite?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
};

export type InvitationInfo = {
  __typename?: 'InvitationInfo';
  allowed?: Maybe<Scalars['Int']['output']>;
  invitationToken: Scalars['UUID']['output'];
  used: Scalars['Int']['output'];
};

export type Login = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MutationRoot = {
  __typename?: 'MutationRoot';
  acceptInvitationWithChannel: Access;
  acceptInvitationWithoutChannel: Access;
  createComment: Comment;
  createGame: Game;
  createGameWithPermission: Game;
  createInvitation: Invitation;
  createResetPassword: Scalars['Boolean']['output'];
  createSession: Session;
  createSessionComment: SessionComment;
  createSessionWithBranch: Session;
  createThread: Thread;
  login: Access;
  resendInvitation: Scalars['Boolean']['output'];
  resetPassword: Access;
  setFavoritePlaySession: PlaySessionFavorite;
  signup: User;
};


export type MutationRootAcceptInvitationWithChannelArgs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  invitationToken: Scalars['UUID']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationRootAcceptInvitationWithoutChannelArgs = {
  firstName: Scalars['String']['input'];
  invitationToken: Scalars['UUID']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationRootCreateCommentArgs = {
  create: CreateComment;
};


export type MutationRootCreateGameArgs = {
  createGame: CreateGame;
};


export type MutationRootCreateGameWithPermissionArgs = {
  authAccess: Scalars['UUID']['input'];
  companyId: Scalars['UUID']['input'];
  gameName: Scalars['String']['input'];
  shortname: Scalars['String']['input'];
};


export type MutationRootCreateInvitationArgs = {
  email: Scalars['String']['input'];
  gameId: Scalars['String']['input'];
  postInvite?: InputMaybe<Scalars['String']['input']>;
  role: Role;
};


export type MutationRootCreateResetPasswordArgs = {
  authId: Scalars['String']['input'];
};


export type MutationRootCreateSessionArgs = {
  shortname: Scalars['String']['input'];
};


export type MutationRootCreateSessionCommentArgs = {
  content: Scalars['String']['input'];
  offset: Scalars['Float']['input'];
  sessionId: Scalars['UUID']['input'];
};


export type MutationRootCreateSessionWithBranchArgs = {
  branchId: Scalars['UUID']['input'];
};


export type MutationRootCreateThreadArgs = {
  create: CreateThread;
};


export type MutationRootLoginArgs = {
  login: Login;
};


export type MutationRootResendInvitationArgs = {
  authId: Scalars['UUID']['input'];
};


export type MutationRootResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  resetToken: Scalars['String']['input'];
};


export type MutationRootSetFavoritePlaySessionArgs = {
  favorite: Scalars['Boolean']['input'];
  playSessionId: Scalars['UUID']['input'];
};


export type MutationRootSignupArgs = {
  signup: Signup;
};

export type PlaySession = {
  __typename?: 'PlaySession';
  createdAt: Scalars['DateTime']['output'];
  favorite?: Maybe<PlaySessionFavorite>;
  game: Game;
  id: Scalars['ID']['output'];
  sessionId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  videoUrl: Scalars['String']['output'];
};

export type PlaySessionFavorite = {
  __typename?: 'PlaySessionFavorite';
  favorite: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export enum PlaySessionFilter {
  All = 'ALL',
  Favorites = 'FAVORITES',
  NotFavorites = 'NOT_FAVORITES'
}

export type Position = {
  __typename?: 'Position';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
  z: Scalars['Float']['output'];
};

export type PositionInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
  z: Scalars['Float']['input'];
};

export type QueryRoot = {
  __typename?: 'QueryRoot';
  auth?: Maybe<Auth>;
  game?: Maybe<Game>;
  gameByShortname?: Maybe<Game>;
  games: Array<Game>;
  invitation?: Maybe<Invitation>;
  invitations: Array<Invitation>;
  playSession?: Maybe<PlaySession>;
  session?: Maybe<Session>;
  user?: Maybe<User>;
};


export type QueryRootGameArgs = {
  gameId: Scalars['UUID']['input'];
};


export type QueryRootGameByShortnameArgs = {
  shortname: Scalars['String']['input'];
};


export type QueryRootInvitationArgs = {
  invitationToken: Scalars['String']['input'];
};


export type QueryRootPlaySessionArgs = {
  id: Scalars['String']['input'];
};


export type QueryRootSessionArgs = {
  sessionId: Scalars['UUID']['input'];
};

export enum Role {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  Guest = 'GUEST',
  User = 'USER'
}

export type Session = {
  __typename?: 'Session';
  allocation?: Maybe<Allocation>;
  id: Scalars['ID']['output'];
  version: Version;
};

export type SessionComment = {
  __typename?: 'SessionComment';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  offset: Scalars['Float']['output'];
  sessionId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type SessionEvent = {
  __typename?: 'SessionEvent';
  createdAt: Scalars['DateTime']['output'];
  gameId: Scalars['UUID']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  sessionId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type SessionInfo = SessionComment | SessionEvent;

export type Signup = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gameName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  shortname: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type Stats = {
  __typename?: 'Stats';
  averageGameplayTime: Scalars['String']['output'];
  cohort: Array<Cohort>;
  events: Array<EventStats>;
  numberOfPlayers: Scalars['Int']['output'];
  timeline: Array<UniquePing>;
  totalGameplayTime: Scalars['String']['output'];
};

export type SubscriptionRoot = {
  __typename?: 'SubscriptionRoot';
  getCommentUpdates: Comment;
  getCommentsForGame: Array<Comment>;
  getCommentsForThread: ThreadsMeta;
  getPlaySessions: Array<PlaySession>;
  getSessionComments: Array<SessionInfo>;
  getThreads: Array<Thread>;
};


export type SubscriptionRootGetCommentsForGameArgs = {
  gameId: Scalars['UUID']['input'];
};


export type SubscriptionRootGetCommentsForThreadArgs = {
  threadId: Scalars['UUID']['input'];
};


export type SubscriptionRootGetPlaySessionsArgs = {
  filter: PlaySessionFilter;
  gameId: Scalars['UUID']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type SubscriptionRootGetSessionCommentsArgs = {
  sessionId: Scalars['UUID']['input'];
};


export type SubscriptionRootGetThreadsArgs = {
  gameId: Scalars['UUID']['input'];
  levelId: Scalars['UUID']['input'];
};

export type Thread = {
  __typename?: 'Thread';
  author: Auth;
  comments: Array<Comment>;
  id: Scalars['ID']['output'];
  meta: CommentMeta;
  position: Position;
  title: Scalars['String']['output'];
};

export type ThreadsMeta = {
  __typename?: 'ThreadsMeta';
  comments: Array<Comment>;
  thread: Thread;
};

export type UniquePing = {
  __typename?: 'UniquePing';
  bucket: Scalars['DateTime']['output'];
  uniquePings: Scalars['Int']['output'];
};

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
};

export type Version = {
  __typename?: 'Version';
  branch: Branch;
  clientStorage?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  environment: Environment;
  executable?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sha: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

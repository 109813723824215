import React from 'react';

import styled from '@emotion/styled';
import { ContextTypes, BackgroundTypes } from 'themes';

type ClickProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

type Props = {
  children: React.ReactNode;
};

export const Main = styled.p<ClickProps>(({ theme, onClick }) => [
  {
    fontFamily: theme.legacy.fonts.secondary,
    fontSize: 12,
    lineHeight: '1.0em',
    color: theme.legacy.colors.brown,

    strong: {
      fontWeight: 600,
    },
  },
  theme.legacy.styles.background === BackgroundTypes.Dark && {
    color: theme.legacy.colors.brown,
  },
  theme.legacy.styles.context === ContextTypes.Menu && {
    color: theme.legacy.colors.white,
  },
  onClick && {
    color: theme.legacy.colors.red,
    cursor: 'pointer',
    opacity: '.90',

    '&:hover': {
      opacity: '1',
    },
  },
]);

const Label: React.FC<Props & ClickProps> = ({ children, onClick }) => {
  return <Main onClick={onClick}>{children}</Main>;
};

export default Label;

import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPlaySessionsSubscriptionVariables = Types.Exact<{
  gameId: Types.Scalars['UUID']['input'];
  pageIndex: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  filter: Types.PlaySessionFilter;
}>;


export type GetPlaySessionsSubscription = { __typename?: 'SubscriptionRoot', getPlaySessions: Array<{ __typename?: 'PlaySession', id: string, sessionId: any, videoUrl: string, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, avatarUrl?: string | null } | null, favorite?: { __typename?: 'PlaySessionFavorite', id: string, favorite: boolean } | null }> };


export const GetPlaySessionsDocument = gql`
    subscription GetPlaySessions($gameId: UUID!, $pageIndex: Int!, $pageSize: Int!, $filter: PlaySessionFilter!) {
  getPlaySessions(
    gameId: $gameId
    pageIndex: $pageIndex
    pageSize: $pageSize
    filter: $filter
  ) {
    id
    sessionId
    videoUrl
    createdAt
    updatedAt
    user {
      id
      firstName
      lastName
      avatarUrl
    }
    favorite {
      id
      favorite
    }
  }
}
    `;

/**
 * __useGetPlaySessionsSubscription__
 *
 * To run a query within a React component, call `useGetPlaySessionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaySessionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaySessionsSubscription({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      pageIndex: // value for 'pageIndex'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPlaySessionsSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetPlaySessionsSubscription, GetPlaySessionsSubscriptionVariables> & ({ variables: GetPlaySessionsSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetPlaySessionsSubscription, GetPlaySessionsSubscriptionVariables>(GetPlaySessionsDocument, options);
      }
export type GetPlaySessionsSubscriptionHookResult = ReturnType<typeof useGetPlaySessionsSubscription>;
export type GetPlaySessionsSubscriptionResult = Apollo.SubscriptionResult<GetPlaySessionsSubscription>;
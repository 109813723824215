import styled from '@emotion/styled';
import { Grid } from 'renderer/utils/styled';

export const Main = styled.canvas(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: '#000',
}));

export const Scroll = styled.div(() => ({
  overflowY: 'auto',
  height: '100%',
}));

export const Average = styled.span(({ theme }) => ({
  fontSize: 30,
  color: theme.colors.brand,
}));

export const Sub = styled.span(() => ({
  fontSize: 12,
}));

export const Table = styled.table(() => ({
  borderCollapse: 'collapse',
}));

export const TableRow = styled.tr(() => ({}));

export const TableHeader = styled.th(({ theme }) => ({
  padding: Grid.L,
  color: theme.colors.gray5,
  fontFamily: theme.fonts.sans,
  fontSize: 11,
}));

export const TableCell = styled.td(({ theme }) => ({
  background: theme.colors.gray0,
  padding: Grid.L,

  border: `1px solid ${theme.colors.gray0}`,
  textAlign: 'left',
}));

import React from 'react';
import styled from '@emotion/styled';

const Path = styled.path(() => ({
  strokeWidth: 2,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeMiterlimit: 10,
  fill: 'currentcolor',
}));

const Svg = styled.div(({ theme }) => ({
  color: theme.colors.green3,
  transition: 'color 300ms ease-in-out',

  ':hover': {
    color: theme.colors.white,
  },
}));

const CheckmarkIcon: React.FC = () => (
  <Svg>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M12 4C7.584 4 4 7.584 4 12C4 16.416 7.584 20 12 20C16.416 20 20 16.416 20 12C20 7.584 16.416 4 12 4ZM12 18.4C8.472 18.4 5.6 15.528 5.6 12C5.6 8.472 8.472 5.6 12 5.6C15.528 5.6 18.4 8.472 18.4 12C18.4 15.528 15.528 18.4 12 18.4ZM16.238 9.03401C15.9257 8.71953 15.4174 8.71863 15.104 9.032L10.4 13.736L8.89254 12.2344C8.58058 11.9236 8.07591 11.9241 7.76455 12.2355C7.45276 12.5472 7.45276 13.0528 7.76455 13.3645L10.4 16L16.236 10.164C16.5478 9.85219 16.5487 9.34692 16.238 9.03401Z"
        fill="#6FCF97"
      />
    </svg>
  </Svg>
);

export default CheckmarkIcon;

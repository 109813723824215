import styled from '@emotion/styled';
import { padding } from 'polished';
import { Weight, Radius, Grid } from 'renderer/utils/styled';

export const Row = styled.div(() => ({
  display: 'flex',
  alignItems: 'baseline',
  gap: '10px',
  ...padding(Grid.M, Grid.L),
}));

export const ErrorMessage = styled.div(({ theme }) => [
  {
    color: theme.legacy.colors.brown,
    fontSize: 11,
  },
]);

export const Field = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'baseline',

  color: theme.colors.white,
  fontFamily: theme.fonts.sans,
  fontSize: 16,
  fontWeight: Weight.LIGHT,

  '.DraftEditor-root': {
    width: '100%',
  },
  '.public-DraftEditorPlaceholder-inner': {
    color: theme.colors.gray3,
    ...padding(0, Grid.S),
  },
}));

export const Label = styled.label(({ theme }) => [
  {
    fontSize: 13,
    fontFamily: theme.fonts.sans,
    fontWeight: Weight.REGULAR,
    color: theme.colors.gray4,
  },
]);

export const Input = styled.input(({ theme }) => [
  {
    width: '100%',
    fontSize: 11,
    borderRadius: Radius.S,
    background: theme.colors.gray1,
    color: theme.colors.gray6,
    fontFamily: theme.fonts.sans,
    outline: 'none',
    border: 0,
    ...padding(Grid.M),
  },
]);

import styled from '@emotion/styled';
import { padding, margin } from 'polished';
import { Grid } from 'renderer/utils/styled';

export const ErrorMessage = styled.div(({ theme }) => [
  {
    color: theme.colors.red,
    fontSize: 11,
  },
]);

export const Field = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.sans,
  width: '100%',
  ...padding(Grid.L, 0),
}));

export const Workarea = styled.div(({ theme }) => ({
  width: '100%',
  background: theme.colors.gray1,
  borderRadius: 4,
}));

export const Label = styled.label(({ theme }) => [
  {
    fontFamily: theme.fonts.sans,
    display: 'block',
    fontSize: 12,
    width: '100%',
    paddingBottom: Grid.L,
    color: theme.colors.gray4,
  },
]);

export const TextArea = styled.textarea(({ theme }) => [
  {
    border: 0,
    boxSizing: 'border-box',
    background: 'transparent',
    fontFamily: theme.fonts.sans,
    fontWeight: 'normal',
    outline: 0,
    resize: 'none',
    width: '100%',
    color: theme.colors.white,
    fontSize: 14,
    ...padding(Grid.L),
  },
]);

export const Children = styled.div(({ theme }) => ({
  width: '100%',
  boxSizing: 'border-box',
  ...padding(Grid.M, Grid.L),
  ...margin(0, 0, Grid.L, 0),
}));

import React from 'react';

import { ThemeProvider } from '@emotion/react';
import { groupBy } from 'lodash';
import { Helmet } from 'react-helmet';

import { darkTheme } from 'renderer/utils/styled';

import * as s from './styles';

export const Header: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Header.displayName = 'header';

export const Scoped: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Scoped.displayName = 'scopedActions';

export const Center: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Center.displayName = 'center';

export const BottomSidebar: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
BottomSidebar.displayName = 'bottomSidebar';

export const LeftSidebar: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
LeftSidebar.displayName = 'leftSidebar';

export const LeftActions: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
LeftActions.displayName = 'leftActions';

export const RightSidebar: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
RightSidebar.displayName = 'rightSidebar';

export const Notification: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Notification.displayName = 'notifications';

export const Searchbar: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Searchbar.displayName = 'searchbar';

/*
type PanelProps = s.PanelProps & {
  onClick: () => void;
};

export const Panel: React.FC<PanelProps> = ({
  children,
  columns,
  rows,
  startRow,
  startColumn,
  highlight,
  onClick,
}) => (
  <s.Panel
    onClick={onClick}
    columns={columns}
    rows={rows}
    highlight={highlight}
    startRow={startRow}
    startColumn={startColumn}
  >
    {children}
  </s.Panel>
);
Panel.displayName = 'panel';
 */

const Viewer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const nodes = React.Children.toArray(children);

  const {
    header,
    scopedActions,
    bottomSidebar,
    center,
    leftSidebar,
    leftActions,
    rightSidebar,
    dialog,
    searchbar,
    notifications,
    // @ts-ignore
  } = groupBy(nodes, (node) => node.type.displayName);

  return (
    <ThemeProvider theme={darkTheme}>
      <Helmet>
        <title>Ultimate</title>
      </Helmet>
      <s.World
        scoped={scopedActions?.length > 0}
        left={leftSidebar?.length > 0}
        right={rightSidebar?.length > 0}
        footer={bottomSidebar?.length > 0}
      >
        <s.Header>{header}</s.Header>
        {scopedActions?.length > 0 && <s.Scoped>{scopedActions}</s.Scoped>}
        <s.Center>{center}</s.Center>
        {leftSidebar?.length > 0 && (
          <s.Left>
            <s.LeftMain>
              <s.ThinPanel>
                <s.Scroll>{leftSidebar}</s.Scroll>
              </s.ThinPanel>
            </s.LeftMain>
            <s.LeftActions>{leftActions}</s.LeftActions>
          </s.Left>
        )}
        {rightSidebar?.length > 0 && (
          <s.Right>
            <s.ThinPanel>
              <s.Scroll>{rightSidebar}</s.Scroll>
            </s.ThinPanel>
          </s.Right>
        )}
        {bottomSidebar?.length > 0 && (
          <s.Bottom>
            <s.ThinPanel>{bottomSidebar}</s.ThinPanel>
          </s.Bottom>
        )}
        <s.Search>{searchbar}</s.Search>
        {dialog?.length > 0 && <s.Dialogs>{dialog}</s.Dialogs>}
        {notifications?.length > 0 && <s.Notifications>{notifications}</s.Notifications>}
      </s.World>
    </ThemeProvider>
  );
};

export default Viewer;

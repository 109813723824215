import React from 'react';

import styled from '@emotion/styled';
import { ContextTypes, BackgroundTypes } from 'themes';

const Main = styled.h2(({ theme }) => [
  {
    fontFamily: theme.legacy.fonts.primary,
    fontSize: 16,
    fontWeight: 100,
    lineHeight: '1.0em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  theme.legacy.styles.context === ContextTypes.Banner && {
    textAlign: 'center',
    display: 'inline',
    fontSize: 40,
  },
  theme.legacy.styles.context === ContextTypes.Hero && {
    textShadow: '2px 2px 5px rgba(0, 0, 0, 1)',
  },
  theme.legacy.styles.background === BackgroundTypes.Light && {
    color: theme.legacy.colors.black,
  },
  theme.legacy.styles.background === BackgroundTypes.Dark && {
    color: theme.legacy.colors.white,
  },
  theme.legacy.styles.context === ContextTypes.Focus && {
    textAlign: 'center',
    display: 'inline',
    textShadow: '0px 0px 20px rgb(142, 18, 18),0px 0px 20px rgb(0, 0, 0)',
    fontSize: 86,
    color: theme.legacy.colors.red,
  },
]);

const Header: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Main>{children}</Main>;
};

export default Header;

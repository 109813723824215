import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserQueryVariables = Types.Exact<{
  shortname: Types.Scalars['String']['input'];
}>;


export type GetUserQuery = { __typename?: 'QueryRoot', auth?: { __typename?: 'Auth', user: { __typename?: 'User', id: string, firstName: string, lastName: string }, invitationInfoByShortname: { __typename?: 'InvitationInfo', used: number, allowed?: number | null, invitationToken: any } } | null, gameByShortname?: { __typename?: 'Game', id: string, name: string, shortname: string, stats: { __typename?: 'Stats', averageGameplayTime: string, totalGameplayTime: string, numberOfPlayers: number, timeline: Array<{ __typename?: 'UniquePing', uniquePings: number, bucket: any }>, events: Array<{ __typename?: 'EventStats', message: string, median: number, min: number, max: number, firstQuartile: number, thirdQuartile: number }>, cohort: Array<{ __typename?: 'Cohort', cohortBucket: any, returnBucket: any, bucketNumber: number, returningUsers: number, totalCohortUsers: number, totalTimeSeconds: number, retentionRate: number }> }, company: { __typename?: 'Company', id: string, name: string }, branches: Array<{ __typename?: 'Branch', id: string, name: string, createdAt: any, updatedAt: any }> } | null };

export type CreateSessionMutationVariables = Types.Exact<{
  branchId: Types.Scalars['UUID']['input'];
}>;


export type CreateSessionMutation = { __typename?: 'MutationRoot', createSessionWithBranch: { __typename?: 'Session', id: string } };


export const GetUserDocument = gql`
    query GetUser($shortname: String!) {
  auth {
    user {
      id
      firstName
      lastName
    }
    invitationInfoByShortname(shortname: $shortname) {
      used
      allowed
      invitationToken
    }
  }
  gameByShortname(shortname: $shortname) {
    id
    name
    shortname
    stats {
      averageGameplayTime
      totalGameplayTime
      numberOfPlayers
      timeline {
        uniquePings
        bucket
      }
      events {
        message
        median
        min
        max
        firstQuartile
        thirdQuartile
      }
      cohort {
        cohortBucket
        returnBucket
        bucketNumber
        returningUsers
        totalCohortUsers
        totalTimeSeconds
        retentionRate
      }
    }
    company {
      id
      name
    }
    branches {
      id
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      shortname: // value for 'shortname'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables> & ({ variables: GetUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export function useGetUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const CreateSessionDocument = gql`
    mutation CreateSession($branchId: UUID!) {
  createSessionWithBranch(branchId: $branchId) {
    id
  }
}
    `;
export type CreateSessionMutationFn = Apollo.MutationFunction<CreateSessionMutation, CreateSessionMutationVariables>;

/**
 * __useCreateSessionMutation__
 *
 * To run a mutation, you first call `useCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionMutation, { data, loading, error }] = useCreateSessionMutation({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useCreateSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionMutation, CreateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, options);
      }
export type CreateSessionMutationHookResult = ReturnType<typeof useCreateSessionMutation>;
export type CreateSessionMutationResult = Apollo.MutationResult<CreateSessionMutation>;
export type CreateSessionMutationOptions = Apollo.BaseMutationOptions<CreateSessionMutation, CreateSessionMutationVariables>;
import React, { useState, useCallback } from 'react';
import { useField } from 'formik';
import { Editor, EditorState, ContentState, KeyBindingUtil, getDefaultKeyBinding } from 'draft-js';
import 'draft-js/dist/Draft.css';

import { ErrorMessage, Row, Field } from './styles';

export type PublicProps = {
  autocomplete?: HTMLInputElement['autocomplete'];
  disabled?: HTMLInputElement['disabled'];
  label?: string;
  maxLength?: number;
  name: string;
  placeholder?: HTMLInputElement['placeholder'];
  readonly?: HTMLInputElement['readOnly'];
  tabIndex?: number;
  onChange?: (props: EditorState) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  decorator?: any;
};

type PrivateProps = PublicProps & {
  error?: string | null;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  rows?: number;
  keyBindings: (event: any) => string | null;
  handleKeyCommand: (command: string, state: EditorState) => 'handled' | 'not-handled';
  value: EditorState;
  onChange: (props: EditorState) => void;
};

export const Private = React.forwardRef<HTMLDivElement, PrivateProps>(
  ({ error, value, handleKeyCommand, placeholder, onChange, keyBindings }, ref) => {
    return (
      <Row>
        <Field>
          <Editor
            ref={(node) => {
              // @ts-ignore
              ref.current = node;
            }}
            keyBindingFn={keyBindings}
            handleKeyCommand={handleKeyCommand}
            placeholder={placeholder}
            onChange={onChange}
            editorState={value}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Field>
      </Row>
    );
  }
);

const Public = React.forwardRef<HTMLDivElement, PublicProps>(
  ({ name, onKeyDown, onChange, decorator, ...rest }, ref) => {
    const [field, meta, helper] = useField(name);
    const [currentState, setState] = useState(
      EditorState.createWithContent(ContentState.createFromText(field.value), decorator)
    );

    const handleOnBlur = useCallback(
      (event: any) => {
        if (field.onBlur) {
          field.onBlur(event);
        }
      },
      [field]
    );

    const handleOnChange = useCallback(
      (event: EditorState) => {
        const text = event.getCurrentContent().getPlainText() ?? '';
        if (event.getCurrentContent().isEmpty()) {
          return;
        }

        if (onChange) onChange(event);
        setState(event);
        helper.setValue(text);
      },
      [helper, onChange]
    );

    const handleKeyCommand = useCallback(
      (command: string, _: EditorState): 'handled' | 'not-handled' => {
        if (command === 'save') {
          //onSubmit(state.getCurrentContent().getPlainText() ?? '');
          return 'handled';
        } else if (command === 'new-line') {
          return 'handled';
        } else if (command === 'close') {
          // @ts-ignore
          if (ref) ref.current.blur();
          setState(EditorState.createWithContent(ContentState.createFromText(''), decorator));
          helper.setValue('');
          return 'handled';
        }

        return 'not-handled';
      },
      [ref, setState, decorator, helper]
    );

    const keyBindings = useCallback(
      (event: any): string | null => {
        if (onKeyDown) onKeyDown(event);

        if (event.key === 'Enter' && KeyBindingUtil.hasCommandModifier(event)) {
          return 'new-line';
        } else if (event.key === 'Enter') {
          return 'save';
        } else if (event.key === 'Escape') {
          return 'close';
        }

        return getDefaultKeyBinding(event);
      },
      [onKeyDown]
    );

    return (
      <Private
        {...rest}
        ref={ref}
        error={meta.touched && meta.error ? meta.error : null}
        name={name}
        handleKeyCommand={handleKeyCommand}
        keyBindings={keyBindings}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        value={currentState}
      />
    );
  }
);

export default Public;

import React from 'react';
import { observer } from 'mobx-react';

import { Form, Formik, FormikHelpers } from 'formik';
import Comment, { Footer } from 'renderer/system/molecules/Comment';
import Event, { Footer as EventFooter } from 'renderer/system/molecules/Event';
import Textarea from 'renderer/components/inputs/Textarea';
import HiddenSubmit from 'renderer/components/inputs/HiddenSubmit';

import Section from 'renderer/system/misc/Section';
import {
  GetSessionCommentsSubscription,
  useCreateSessionCommentMutation,
  useGetSessionCommentsSubscription,
} from './queries';
import * as s from './styles';
import Eyebrow from 'renderer/system/typography/Eyebrow';
import Caption, { CaptionMode } from 'renderer/system/typography/Caption';
import { elapsed } from 'renderer/utils/datetime';
/*
import FavoriteIcon from 'renderer/icons/Favorite';
import Button, { ButtonModes, ButtonSizes } from 'renderer/system/atoms/Button';
import UnfavoriteIcon from 'renderer/icons/Unfavorite';
import Row from 'renderer/system/misc/Row';
*/

type CreateComment = {
  content: string;
};

type SessionCommentsManagerProps = {
  id: string;
  getCurrentTime: () => number;
  session: any;
  onClickComment?: (
    comment: ArrayElement<GetSessionCommentsSubscription['getSessionComments']>
  ) => () => void;
};

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

const SessionCommentsManager: React.FC<SessionCommentsManagerProps> = ({
  id,
  onClickComment,
  getCurrentTime,
  session,
}) => {
  const [create, commentMeta] = useCreateSessionCommentMutation();

  const commentsQuery = useGetSessionCommentsSubscription({
    variables: { id },
  });

  const initialValues = {
    content: '',
  };

  /*
  const [setFavoritePlaySession] = useSetFavoritePlaySessionMutation({
    onCompleted: (data) => {
      if (!session) return;

      // NOTE: When we set a favorite for the first time, we don't have an id for this PlaySessionFavorite.
      // So, we need to refetch the session in order to get the new value for it.
      if (!session.favorite) {
        playSessionQuery.refetch();
      }
    },
  });
  */

  const handleSubmitForm = async (values: CreateComment, formik: FormikHelpers<CreateComment>) => {
    await create({
      variables: {
        sessionId: id,
        offset: getCurrentTime(),
        content: values.content,
      },
    });
    formik.resetForm();
  };

  if (commentsQuery.loading) {
    return <div>Loading...</div>;
  }

  const comments = commentsQuery.data?.getSessionComments ?? [];

  return (
    <s.Area>
      {/*
      <s.Header>
        <Row>
          <Button
            title="Download"
            mode={ButtonModes.PRIMARY}
            size={ButtonSizes.SMALL}
            onClick={async () => {
              await window?.fs.saveFileUrl(session.videoUrl);
            }}
          >
            Download
          </Button>

          {session.favorite?.favorite && (
            <Button
              title="Click to unfavorite"
              mode={ButtonModes.TERTIARY}
              size={ButtonSizes.SMALL}
              onClick={async () => {
                await setFavoritePlaySession({ variables: { playSessionId: session.id, favorite: false } });
              }}
            >
              <FavoriteIcon />
            </Button>
          )}

          {!session.favorite?.favorite && (
            <Button
              title="Click to favorite"
              mode={ButtonModes.TERTIARY}
              size={ButtonSizes.SMALL}
              onClick={async () => {
                await setFavoritePlaySession({ variables: { playSessionId: session.id, favorite: true } });
              }}
            >
              <UnfavoriteIcon />
            </Button>
          )}
        </Row>
      </s.Header>
      */}

      <s.Main>
        <s.Overflow>
          {comments.length <= 0 && (
            <Section horizontal={3} vertical={3}>
              <Eyebrow>No comments yet.</Eyebrow>
            </Section>
          )}
          {comments.map((info) => {
            switch (info.__typename) {
              case 'SessionComment': {
                const user = info.user ? `${info.user.firstName} ${info.user.lastName}` : 'Unknown';
                return (
                  <Comment
                    key={info.id}
                    title={user}
                    time={new Date(info.createdAt)}
                    content={info.content}
                    onClick={onClickComment?.(info)}
                  >
                    <Footer>
                      <Caption mode={CaptionMode.SECONDARY}>@ {elapsed(info.offset)}</Caption>
                    </Footer>
                  </Comment>
                );
              }
              case 'SessionEvent': {
                const infoCreated = new Date(info.createdAt);
                const sessionCreated = new Date(session.createdAt);
                const offset = infoCreated.getTime() - sessionCreated.getTime();
                return (
                  <Event
                    key={info.id}
                    title={info.message}
                    time={new Date(info.createdAt)}
                    onClick={onClickComment?.(info)}
                  >
                    <EventFooter>
                      <Caption mode={CaptionMode.SECONDARY}>@ {elapsed(offset / 1000)}</Caption>
                    </EventFooter>
                  </Event>
                );
              }
            }
          })}
        </s.Overflow>
      </s.Main>
      <s.Footer>
        <Formik<CreateComment> initialValues={initialValues} onSubmit={handleSubmitForm}>
          {() => (
            <Form>
              <Textarea placeholder="Add comment..." name="content" disabled={commentMeta.loading} />
              <HiddenSubmit />
            </Form>
          )}
        </Formik>
      </s.Footer>
    </s.Area>
  );
};

export default observer(SessionCommentsManager);

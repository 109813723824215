import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvitationsQueryVariables = Types.Exact<{
  gameId: Types.Scalars['UUID']['input'];
}>;


export type InvitationsQuery = { __typename?: 'QueryRoot', auth?: { __typename?: 'Auth', role?: Types.Role | null, invitationInfo: { __typename?: 'InvitationInfo', used: number, allowed?: number | null, invitationToken: any } } | null, invitations: Array<{ __typename?: 'Invitation', id: string, acceptedAt?: any | null, createdAt: any, invitee: { __typename?: 'Auth', id: string, email?: string | null } }> };

export type InviteMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  gameId: Types.Scalars['String']['input'];
  postInvite?: Types.InputMaybe<Types.Scalars['String']['input']>;
  role: Types.Role;
}>;


export type InviteMutation = { __typename?: 'MutationRoot', createInvitation: { __typename?: 'Invitation', id: string } };


export const InvitationsDocument = gql`
    query Invitations($gameId: UUID!) {
  auth {
    role(gameId: $gameId)
    invitationInfo(gameId: $gameId) {
      used
      allowed
      invitationToken
    }
  }
  invitations {
    id
    acceptedAt
    createdAt
    invitee {
      id
      email
    }
  }
}
    `;

/**
 * __useInvitationsQuery__
 *
 * To run a query within a React component, call `useInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationsQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useInvitationsQuery(baseOptions: Apollo.QueryHookOptions<InvitationsQuery, InvitationsQueryVariables> & ({ variables: InvitationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, options);
      }
export function useInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationsQuery, InvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, options);
        }
export function useInvitationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InvitationsQuery, InvitationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, options);
        }
export type InvitationsQueryHookResult = ReturnType<typeof useInvitationsQuery>;
export type InvitationsLazyQueryHookResult = ReturnType<typeof useInvitationsLazyQuery>;
export type InvitationsSuspenseQueryHookResult = ReturnType<typeof useInvitationsSuspenseQuery>;
export type InvitationsQueryResult = Apollo.QueryResult<InvitationsQuery, InvitationsQueryVariables>;
export const InviteDocument = gql`
    mutation Invite($email: String!, $gameId: String!, $postInvite: String, $role: Role!) {
  createInvitation(
    email: $email
    gameId: $gameId
    postInvite: $postInvite
    role: $role
  ) {
    id
  }
}
    `;
export type InviteMutationFn = Apollo.MutationFunction<InviteMutation, InviteMutationVariables>;

/**
 * __useInviteMutation__
 *
 * To run a mutation, you first call `useInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMutation, { data, loading, error }] = useInviteMutation({
 *   variables: {
 *      email: // value for 'email'
 *      gameId: // value for 'gameId'
 *      postInvite: // value for 'postInvite'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useInviteMutation(baseOptions?: Apollo.MutationHookOptions<InviteMutation, InviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteMutation, InviteMutationVariables>(InviteDocument, options);
      }
export type InviteMutationHookResult = ReturnType<typeof useInviteMutation>;
export type InviteMutationResult = Apollo.MutationResult<InviteMutation>;
export type InviteMutationOptions = Apollo.BaseMutationOptions<InviteMutation, InviteMutationVariables>;
import styled from '@emotion/styled';
import { padding, margin, darken } from 'polished';
import { Weight, Radius, Grid, LineHeight } from 'renderer/utils/styled';

export enum CardModes {
  HORIZONTAL,
  HORIZONTAL_GAMESELECT,
  VERTICAL,
}

export enum VisualMode {
  NORMAL,
  SECONDARY,
}

type ModeProps = {
  mode: CardModes;
  visual?: VisualMode;
};

type CardProps = {
  onClick?: any;
};

export const Main = styled.div<CardProps & ModeProps>(
  ({ theme, mode, onClick, visual = VisualMode.NORMAL }) => [
    visual === VisualMode.NORMAL && {
      background: theme.colors.gray0,
    },
    {
      borderRadius: Radius.L,
      transition: 'color 300ms ease-in-out,background 300ms ease-in-out',
    },
    mode === CardModes.HORIZONTAL && {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    mode === CardModes.HORIZONTAL_GAMESELECT && {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '50%',
      minWidth: '300px',
      textOverflow: 'ellipsis',
    },
    mode === CardModes.VERTICAL && {
      width: 200,
      ...padding(Grid.M),
    },
    onClick && {
      cursor: 'pointer',
      ':hover': {
        background: darken(0.5, theme.colors.gray0),
      },
    },
  ]
);

export const Image = styled.img<ModeProps>(({ mode }) => [
  mode === CardModes.HORIZONTAL && {
    width: 'auto',
    height: '100%',
    maxHeight: 150,
    boxSizing: 'border-box',
    borderRadius: Radius.M,

    ...margin(0, Grid.M, 0, 0),
  },
  mode === CardModes.VERTICAL && {
    width: '100%',
    height: 'auto',
    boxSizing: 'border-box',
    borderRadius: Radius.M + Grid.M,

    ...padding(Grid.M, Grid.M, 0, Grid.M),
  },
]);

type ContentProps = ModeProps & { image?: any };

export const Content = styled.div<ContentProps>(({ mode, image }) => [
  mode === CardModes.HORIZONTAL_GAMESELECT && {
    width: '100%',
  },
  mode === CardModes.HORIZONTAL &&
    !image && {
      width: '70%',
    },
  mode === CardModes.HORIZONTAL &&
    image && {
      width: 'calc(70% - 72px)',
    },
  mode === CardModes.HORIZONTAL && {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: '1 0 auto',

    ...padding(0, Grid.M),
  },
  mode === CardModes.VERTICAL && {
    ...padding(Grid.M, 0),
  },
]);

export const Actions = styled.div<ModeProps>(({ mode }) => [
  mode === CardModes.HORIZONTAL && {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'right',
    flex: '0 1 auto',

    ...padding(0, Grid.M),
  },
  mode === CardModes.VERTICAL && {
    ...padding(Grid.M, 0),
  },
]);

export const Section = styled.div<ModeProps>(({ mode }) => [
  mode === CardModes.HORIZONTAL && {
    ...padding(Grid.M, 0),
  },
  mode === CardModes.HORIZONTAL_GAMESELECT && {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '50px',
    minWidth: '300px',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  mode === CardModes.VERTICAL && {
    ...padding(Grid.M, 0),
  },
]);

export const Header = styled.div<ModeProps>(({ theme, mode }) => [
  {
    fontFamily: theme.fonts.sans,
    fontSize: 12,
    lineHeight: LineHeight.SINGLE,
    color: theme.colors.white,
    ...padding(0, Grid.M),
  },
  mode === CardModes.HORIZONTAL && {
    fontWeight: Weight.REGULAR,
  },
  mode === CardModes.HORIZONTAL_GAMESELECT && {
    fontWeight: Weight.REGULAR,
    textOverflow: 'ellipsis',
  },
  mode === CardModes.VERTICAL && {
    fontWeight: Weight.BOLD,
  },
]);

export const Subheader = styled.div(({ theme }) => ({
  fontFamily: theme.fonts.sans,
  fontSize: 10,
  lineHeight: LineHeight.SINGLE,
  fontWeight: Weight.REGULAR,
  color: theme.colors.brand,
  ...padding(Grid.M),
}));

export const Body = styled.div<ModeProps>(({ theme, mode }) => [
  {
    fontFamily: theme.fonts.sans,
    fontWeight: Weight.REGULAR,
    color: theme.colors.gray4,
    lineHeight: LineHeight.NORMAL,
    ...padding(Grid.M),
  },
  mode === CardModes.HORIZONTAL && {
    fontSize: 10,
  },
  mode === CardModes.HORIZONTAL_GAMESELECT && {
    fontSize: 10,
    width: '100%',
    overflowX: 'auto',
  },
  mode === CardModes.VERTICAL && {
    fontSize: 12,
  },
]);

export const Label = styled.div(() => [
  {
    display: 'flex',
    gap: Grid.M,
    wordWrap: 'break-word',
  },
]);

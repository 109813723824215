import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { useFocus } from 'renderer/hooks/useFocus';
import Smart from 'renderer/components/inputs/Smart';
import { UpdateState, useLevelData } from 'renderer/data/level';
import Checkmark from 'renderer/icons/Checkmark';

import * as s from './styles';

type SearchInfo = {
  search: string;
};

const initialValues = {
  search: '',
};

const Search: React.FC<React.PropsWithChildren> = ({ children }) => {
  const level = useLevelData();
  const [searchRef, searchActions] = useFocus();
  const handleSearch = useCallback(
    (values: SearchInfo) => {
      level.updateSearch(values.search);
    },
    [level]
  );

  const handleUnfocus = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      searchActions.blur();
    }
  };

  return (
    <s.Wrap>
      <s.Search>
        <Formik<SearchInfo> initialValues={initialValues} onSubmit={handleSearch} validate={handleSearch}>
          <Form>
            <Smart onKeyDown={handleUnfocus} ref={searchRef} name="search" placeholder="Type / to search" />
          </Form>
        </Formik>
      </s.Search>
      <s.EngineStatus>
        <s.LineSpaced>Editor: {level.editorVersion}</s.LineSpaced>
        {level.editor.updateState === UpdateState.UPTODATE && (
          <s.Line>
            Engine is up to date <Checkmark />{' '}
          </s.Line>
        )}
        {level.editor.updateState === UpdateState.DOWNLOADING && (
          <s.LineSpaced>Update detected. Downloading new version.</s.LineSpaced>
        )}
        {level.editor.updateState === UpdateState.UPDATE_AVAILABLE && (
          <s.LineSpaced>Update downloaded. Restart the engine.</s.LineSpaced>
        )}
      </s.EngineStatus>
    </s.Wrap>
  );
};

export default Search;
